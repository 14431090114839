import React from 'react';

function HeroSection() {
  return (
    <section className="hero d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#343a40' }}>
      <div className="hero-content text-center text-white">
        <h1 className="display-4 font-weight-bold">Twoja Polska Wyszukiwarka Internetowa</h1>
        <h2 className="lead mb-4">Szybko znajdź to, czego potrzebujesz</h2>
        <button id="action-button" className="btn btn-light mt-3">Poznaj Więcej</button>
      </div>
    </section>
  );
}

export default HeroSection;
