import React from 'react';

function SearchSection() {
  return (
    <section className="search-section d-flex align-items-center justify-content-center" style={{ height: '100vh', backgroundColor: '#f8f9fa' }}>
      <div className="container text-center">
        <h2>Wyszukaj, czego potrzebujesz</h2>
        <form id="search-form" className="mt-4 d-flex">
          <input type="text" id="search-input" className="form-control me-2" placeholder="Wpisz swoje zapytanie..." />
          <button type="submit" className="btn btn-primary">Szukaj</button>
        </form>
      </div>
    </section>
  );
}

export default SearchSection;
