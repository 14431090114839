import React from 'react';

function CommunitySection() {
  return (
    <section className="community-section py-5 text-center">
      <div className="container">
        <h2>Społeczność Open Source</h2>
        <p>Dołącz do społeczności, która pracuje nad najnowszymi projektami Open Source. Sprawdź nasze repozytoria i dołącz do dyskusji!</p>
        <a href="/open-source" className="btn btn-success btn-lg mt-4">Zobacz projekty</a>
      </div>
    </section>
  );
}

export default CommunitySection;
