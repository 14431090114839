import logo from '../img/logo.png';

function Navbar() {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="OneNetworks Logo" style={{ maxHeight: '80px' }} loading="lazy" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item"><a className="nav-link active" href="documentation/index.html">O projekcie</a></li>
              <li className="nav-item"><a className="nav-link" href="#">Aplikacje</a></li>
              <li className="nav-item"><a className="nav-link" href="#">WirtualnaRedakcja.pl</a></li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
