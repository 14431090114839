import React from 'react';

const ExternalLinks = ({ links }) => {
  return (
    <div className="external-links-container">
      <h2>Ciekawe linki i posty:</h2>
      <ul className="links-list">
        {links.map((link, index) => (
          <li key={index} className="link-item">
            <h3>{link.title}</h3>
            <p>{link.description}</p>
            {link.type === 'linkedin' ? (
              <iframe
                src={link.url}
                width="504"
                height="798"
                frameBorder="0"
                allowFullScreen
                title={link.title}
              ></iframe>
            ) : (
              <iframe
                src={link.url}
                width="100%"
                height="500px"
                frameBorder="0"
                title={link.title}
              ></iframe>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExternalLinks;
