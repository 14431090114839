import React from 'react';

function BlogPosts() {
  return (
    <section className="blog-posts">
      <article className="container">
        <h1 className="mt-4">Cześć wszystkim!</h1>
        <p>Chciałem podzielić się tym, nad czym pracowałem w ostatnim tygodniu. Było trochę technicznych wyzwań, ale też sporo satysfakcji z ich pokonania.</p>
        <section className="mb-4">
          <h2>Uruchomienie serwisu w systemie DNS</h2>
          <p>Pierwszym zadaniem było skonfigurowanie rekordów DNS dla naszego serwisu. Teraz strona jest już widoczna w sieci, co zawsze cieszy po godzinach konfiguracji i testów.</p>
        </section>
        <section className="mb-4">
          <h2>Automatyczne odnawianie certyfikatu SSL</h2>
          <p>Udało mi się też wdrożyć automatyczne odnawianie certyfikatów SSL. Dzięki temu nie muszę pamiętać o ręcznym przedłużaniu certyfikatów, a połączenia z naszą stroną są zawsze bezpieczne.</p>
        </section>
        <section className="mb-4">
          <h2>Konfiguracja serwera Nginx do serwowania landing page</h2>
          <p>Pracowałem nad ustawieniami Nginx, żeby nasza strona główna ładowała się szybciej i bardziej efektywnie.</p>
        </section>
        <section className="mb-4">
          <h2>Eksperymenty w laboratorium Mikrotik RouterOS</h2>
          <p>Na koniec spędziłem trochę czasu w naszym laboratorium z Mikrotik RouterOS. Testowałem różne konfiguracje i funkcje, co dało mi kilka pomysłów na usprawnienie naszej sieci.</p>
        </section>
        <footer>
          <p>To tyle ode mnie na dziś. Jeśli macie pytania lub chcecie podzielić się swoimi doświadczeniami, dajcie znać w komentarzach!</p>
        </footer>
      </article>
    </section>
  );
}

export default BlogPosts;
