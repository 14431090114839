import React from 'react';

function WeatherWidgets() {
  return (
    <section className="widgets py-5 bg-light">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-6">
            <div className="p-4 shadow-sm">
              <h3>Aktualna Pogoda</h3>
              <p id="weather-city">Miasto: Warszawa</p>
              <p id="weather-temp">Temperatura: 20°C</p>
              <button id="update-weather" className="btn btn-info mt-3">Odśwież dane</button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-4 shadow-sm">
              <h3>Jakość Powietrza</h3>
              <p id="air-quality-city">Miasto: Kraków</p>
              <p id="air-quality-index">Indeks AQI: 85 (Średni)</p>
              <button id="update-air-quality" className="btn btn-info mt-3">Odśwież dane</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WeatherWidgets;
