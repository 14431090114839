import React from 'react';

function Footer() {
  return (
    <footer className="py-4 bg-dark text-white text-center">
      <div className="container">
        <p>&copy; 2024 OneNetworks. Wszystkie prawa zastrzeżone.</p>
      </div>
    </footer>
  );
}

export default Footer;
