import React from 'react';
import './App.css';
import './styles/style.css';
import Navbar from './components/Navbar';
import SearchSection from './components/SearchSection';
import HeroSection from './components/HeroSection';
import ExternalLinks from './components/ExternalLinks'; // Import komponentu ExternalLinks
import BlogPosts from './components/BlogPosts';
import CommunitySection from './components/CommunitySection';
import WeatherWidgets from './components/WeatherWidgets';
import Footer from './components/Footer';

function App() {
  const linksData = [
    {
      title: "Post na LinkedIn #1",
      url: "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7241462318335705088",
      type: "linkedin",
      description: "Post związany z cyberbezpieczeństwem na LinkedIn.",
    },
    {
      title: "Ciekawa strona o technologiach",
      url: "https://example.com",
      type: "website",
      description: "Strona prezentująca nowinki ze świata technologii.",
    },
    {
      title: "Post na LinkedIn #2",
      url: "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7251542318436761600",
      type: "linkedin",
      description: "Inny post na LinkedIn o IT.",
    },
  ];

  return (
    <div>
      <Navbar />
      <SearchSection />
      <HeroSection />
      
      {/* Wyświetlenie komponentu ExternalLinks */}
      <ExternalLinks links={linksData} />

      <WeatherWidgets />
      <CommunitySection />
      <BlogPosts />
      <Footer />
    </div>
  );
}

export default App;
